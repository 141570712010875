import React, { useState, useEffect } from "react";
//PRIMEREACT
import { Button } from "primereact/button";
//RUTAS
import { Link } from "react-router-dom";
//ESTILOS
import "./Profile.css";
//COMPONENTES
import Insignias from "../Insignias";
import Compliance from "../Compliance";
import LoaderSimple from "../LoaderSimple";
import ModalLogouts from "./ModalLogouts";
import AdviserProfile from "./AdviserProfile";
import ChargeImgVision from "./ChargeImgVision.js";
// import ChargeImgPeter from "./ChargeImgPeter.js";
// import ChargeImgLove from "./ChargeImgLove.js";
// import ChargeImgRedes from "./ChargeImgRedes.js";
// import ChargeImgPorfileUsers from "./ChargeImgPorfileUsers.js";
//MEDIA
import ImgUser from "../../assets/images/imgUser.png";
// FIREBASE
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
//CONTEXT
import { useUsuario } from "../../context/usuario-context.jsx";
//SERVICES
import { toast } from "react-toastify";
import fglobales from "../../services/fglobales";

function Profile() {
  const { dataUser, resetDataUser, configurations } = useUsuario();
  const [submitted, setSubmitted] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [alliesData, setAlliesData] = useState([]);
  const [branchesData, setBranchesData] = useState([]);
  const [imgPremio, setImgPremio] = useState(null);
  const [imgPremioSrc, setImgPremioSrc] = useState(
    dataUser.userImg ? dataUser.userImg : ImgUser
  );

  useEffect(() => {
    const miFuncionAsincrona = async () => {
      try {
        const docAlly = await fglobales.getDatosABC(
          "allies",
          "alliedCode",
          dataUser.allies[0]
        );

        const docBran = await fglobales.getDatosAB(
          "branches",
          "branchCode",
          dataUser.branches[0]
        );

        setAlliesData(docAlly);
        setBranchesData(docBran);
      } catch (error) {
        console.log(error);
      }
    };

    miFuncionAsincrona();
  }, [dataUser]);

  const logouts = () => {
    signOut(auth)
      .then(function () {})
      .catch(function (error) {
        // An error happened.
        console.log(error);
      });
  };

  const handleCapture = (event) => {
    if (!event) {
      return;
    }
    const files = event.target.files;
    if (!files) {
      return;
    }

    const imgPremio = files[0];
    if (!imgPremio) {
      return;
    }

    const fileTypes = ["image/jpeg", "image/png"];
    if (!fileTypes.includes(imgPremio.type)) {
      toast.error("Subir fotos .jpeg o .png");
      return;
    }

    if (imgPremio.size > 20 * 1024 * 1024) {
      toast.error("Imagen demasiado grande:", imgPremio.size);
      return;
    }

    setImgPremio(imgPremio);
    setImgPremioSrc(URL.createObjectURL(imgPremio));
  };

  const updateUserImg = async () => {
    setSubmitted(true);
    const addPerfilAvatar = await fglobales.addPerfilImg(
      "usersClients",
      dataUser,
      imgPremio
    );

    console.log(addPerfilAvatar);
    resetDataUser();
    setSubmitted(false);
  };

  const actionHiddenMessage = () => {
    setShowMessage(false);
    hideDialog();
  };

  const hideDialog = () => {
    setShowMessage(false);
  };

  return (
    <>
      <div className='cerrarSesion'>
        <Button
          className='p-button-cerrar'
          type='submit'
          onClick={() => {
            setShowMessage(true);
          }}>
          Cerrar sesión
        </Button>
      </div>
      <div className='grid grid-nogutter container-principal'>
        <div className='col-12 md:col-10 md:col-offset-1 mt-4 contentForm'>
          <div className='grid'>
            <div className='col-12 md:col-10 md:col-offset-1'>
              <div className='grid'>
                <div className='col-10 col-offset-1 md:col-offset-0 md:col-3 lg:col-3 sinPT'>
                  <div className='fotoProfile '>
                    <input
                      type='file'
                      name='imgPremio'
                      id='imgPremio'
                      className='SubirFoto'
                      accept='image/*'
                      capture='camera'
                      onChange={handleCapture}
                      disabled={submitted}
                    />
                    <label
                      htmlFor='imgPremio'
                      onChange={handleCapture}
                      disabled={submitted}
                      className='imagenSubir'>
                      {imgPremioSrc ? (
                        <img
                          src={imgPremioSrc}
                          alt={dataUser.fullname}
                        />
                      ) : (
                        <img
                          src={ImgUser}
                          alt={dataUser.fullname}
                        />
                      )}

                      <p className='adjuntoProfile'>
                        {!imgPremio ? (
                          <>
                            Cambiar foto <i className='pi pi-paperclip'></i>
                          </>
                        ) : (
                          <>
                            {imgPremio.name} <i className='pi pi-paperclip'></i>
                          </>
                        )}
                      </p>
                    </label>
                    {imgPremio && imgPremio.name ? (
                      <>
                        <br />
                        <Button
                          className='p-button-brilla'
                          type='submit'
                          disabled={submitted}
                          onClick={() => {
                            updateUserImg();
                          }}>
                          Guardar foto
                        </Button>
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className='col-12 md:col-9 lg:col-9 sinPT'>
                  <div className='fotoProfile'>
                    {alliesData.length > 0 && branchesData.length > 0 ? (
                      <div className='grid'>
                        <div className='col-12 md:col-6'>
                          {alliesData
                            ? alliesData.map((ally) => {
                                return (
                                  <React.Fragment key={ally.alliedCode}>
                                    <p>Aliado</p>
                                    <p className='profileDes aliadosPerfil'>
                                      {ally.allyName}
                                    </p>
                                    <br />
                                    <p>Código aliado</p>
                                    <p className='profileDes aliadosPerfil'>
                                      {ally.alliedCode}
                                    </p>
                                  </React.Fragment>
                                );
                              })
                            : ""}
                        </div>
                        <div className='col-12 md:col-6'>
                          {branchesData
                            ? branchesData.map((su) => {
                                return (
                                  <React.Fragment key={su.branchCode}>
                                    <p>Sucursal</p>
                                    <p className='profileDes aliadosPerfil'>
                                      {su.nameBranch}
                                    </p>
                                    <br />

                                    <p>Código sucursal</p>
                                    <p className='profileDes aliadosPerfil'>
                                      {su.branchCode}
                                    </p>
                                  </React.Fragment>
                                );
                              })
                            : ""}
                          <p className='profileDes aliadosPerfil'>
                            {dataUser.sucursal}
                          </p>

                          <p className='profileDes aliadosPerfil'>
                            {dataUser.sucursal}
                          </p>
                        </div>
                      </div>
                    ) : (
                      <LoaderSimple />
                    )}

                    <p>Nombre del responsable</p>
                    <p className='profileDes'>{dataUser.fullName}</p>
                    <br />

                    <p>N° Identificación</p>
                    <p className='profileDes'>{dataUser.dni}</p>
                    <br />

                    <p>Correo</p>
                    <p className='profileDes'>{dataUser.email}</p>
                    <br />

                    <br />
                    <p className='adjuntoProfile'>
                      <Link to='/perfilusuario'>
                        Editar <i className='pi pi-user-edit'></i>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <ChargeImgVision dataUser={dataUser} />
        {/* <ChargeImg dataUser={dataUser} /> */}
        {/* <ChargeImgRedes dataUser={dataUser} /> */}
        {/* <ChargeImgLove dataUser={dataUser} /> */}
        {/* <ChargeImgPeter dataUser={dataUser} /> */}
        {/* <ChargeImgPorfileUsers dataUser={dataUser} /> */}

        <AdviserProfile dataUser={dataUser} />
        <Compliance
          dataUser={dataUser}
          configurations={configurations}
        />
        <Insignias dataUser={dataUser} />
      </div>

      <ModalLogouts
        showMessage={showMessage}
        actionHidden={actionHiddenMessage}
        logouts={logouts}
      />
    </>
  );
}

export default Profile;
